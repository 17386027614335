<template>
  <Breadcrumb />
  <div class="row">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <form class="row g-3 form-notification-setting-new" novalidate>
            <div v-if="errors.length > 0" class="alert alert-warning">
              <ul class="mb-0">
                <li v-for="error in errors" v-bind:key="error">
                  {{ error }}
                </li>
              </ul>
            </div>
            <div v-if="isSuccess" class="alert alert-success">
              {{
                $t(
                  "Messages.SuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
            <div class="col-md-6">
              <label for="NotificationType" class="form-label required">{{
                $t(
                  "NotificationSettings.NotifierType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <FormSelect
                type="SelectWithLocalData"
                :selectedData="selectedNotifierType"
                :allowEmpty="false"
                :data="notifierTypeData"
                @onChange="onChangeForNotifierType"
              />
            </div>
            <div class="col-md-6">
              <label for="displayName" class="form-label required"
                >{{
                  $t(
                    "NotificationSettings.DisplayName",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="text"
                class="form-control"
                id="displayName"
                v-model="notificationSettingData.displayName"
                :autocomplete="this.$isAutoComplete"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6" v-if="!notifierTypeEmail">
              <label for="userName" class="form-label">{{
                $t(
                  "NotificationSettings.UserName",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                name="userName"
                class="form-control"
                id="userName"
                v-model="notificationSettingData.userName"
                :spellcheck="this.$isTextSpellCheck"
                type="text"
              />
            </div>
            <div class="col-md-6" v-if="!notifierTypeEmail">
              <label for="passwordSms" class="form-label"
                >{{
                  $t(
                    "NotificationSettings.Password",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                name="passwordSms"
                class="form-control"
                id="passwordSms"
                v-model="notificationSettingData.password"
                :spellcheck="this.$isTextSpellCheck"
                type="password"
              />
            </div>
            <div class="col-md-6" v-if="notifierTypeEmail">
              <label for="passwordEmail" class="form-label"
                >{{
                  $t(
                    "NotificationSettings.Password",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                type="password"
                class="form-control"
                id="passwordEmail"
                v-model="notificationSettingData.password"
                :spellcheck="this.$isTextSpellCheck"
              />
            </div>
            <div class="col-md-6" v-if="notifierTypeEmail">
              <label for="senderEmail" class="form-label required">{{
                $t(
                  "NotificationSettings.SenderEmail",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                name="senderEmail"
                class="form-control"
                id="senderEmail"
                v-model="notificationSettingData.senderEmail"
                :spellcheck="this.$isTextSpellCheck"
                type="text"
                required
              />
            </div>
            <div class="col-md-6" v-if="notifierTypeEmail">
              <label for="port" class="form-label required"
                >{{
                  $t(
                    "NotificationSettings.Port",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </label>
              <input
                name="port"
                class="form-control"
                id="port"
                v-model="notificationSettingData.port"
                type="number"
                :spellcheck="this.$isTextSpellCheck"
                required
              />
            </div>
            <div class="col-md-6" v-if="notifierTypeEmail">
              <label for="host" class="form-label required">{{
                $t(
                  "NotificationSettings.Host",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                name="host"
                class="form-control"
                id="host"
                v-model="notificationSettingData.host"
                :spellcheck="this.$isTextSpellCheck"
                type="text"
                required
              />
            </div>
            <div class="col-md-6">
              <div class="form-check" v-if="notifierTypeEmail">
                <label for="IsExchangeServer" class="form-label">{{
                  $t(
                    "NotificationSettings.IsExchangeServer",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsExchangeServer"
                  v-model="notificationSettingData.isExchangeServer"
                />
              </div>
              <div class="form-check" v-if="notifierTypeEmail">
                <label for="SSL" class="form-label">{{
                  $t(
                    "NotificationSettings.SSL",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="SSL"
                  v-model="notificationSettingData.isSsl"
                />
              </div>
              <div class="form-check">
                <label for="IsActive" class="form-label">{{
                  $t(
                    "BaseModelFields.IsActive",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsActive"
                  v-model="notificationSettingData.isActive"
                />
              </div>
              <div class="form-check">
                <label for="IsBeingUsedForSystemEmail" class="form-label">{{
                  $t(
                    "NotificationSettings.IsBeingUsedForSystemEmail",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}</label>
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="IsBeingUsedForSystemEmail"
                  v-model="notificationSettingData.isBeingUsedForSystemEmail"
                />
              </div>
            </div>
            <div class="col-md-6" v-if="notificationSettingData.notifierTypeId">
              <template v-if="notifierTypeEmail">
                <button
                  type="button"
                  class="btn btn-warning me-2"
                  data-bs-target="#modalNotificationSettingTest"
                  data-bs-toggle="modal"
                >
                  <i class="bi bi-envelope"></i>
                  {{
                    $t(
                      "Components.NotificationSettingTestModal.EmailTest",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="btn btn-success me-2"
                  @click="smsBalanceButtonClicked"
                  data-bs-target="#modalNotificationSMSBalance"
                  data-bs-toggle="modal"
                >
                  {{
                    $t(
                      "Components.NotificationSMSBalanceModal.Title",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
                <button
                  type="button"
                  class="btn btn-warning"
                  data-bs-target="#modalNotificationSettingTest"
                  data-bs-toggle="modal"
                >
                  <i class="bi bi-phone"></i>
                  {{
                    $t(
                      "Components.NotificationSettingTestModal.SMSTest",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}
                </button>
              </template>
            </div>
            <ActionButtons
              @methodSave="onSubmit('0', 'btn-save')"
              @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
              @clear="onClear()"
              :isNewRecord="true"
            />
          </form>
        </div>
      </div>
    </div>
  </div>
  <NotificationSettingTestModal
    :isEmailTest="notifierTypeEmail"
    :userName="notificationSettingData.userName"
    :password="notificationSettingData.password"
    :displayName="notificationSettingData.displayName"
    :isExchangeServer="notificationSettingData.isExchangeServer"
    :domain="notificationSettingData.domain"
    :senderEmail="notificationSettingData.senderEmail"
    :host="notificationSettingData.host"
    :port="notificationSettingData.port"
    :isSsl="notificationSettingData.isSsl"
  />
  <NotificationSMSBalanceModal
    :userName="notificationSettingData.userName"
    :password="notificationSettingData.password"
    :modalOpenButtonClick="smsBalanceButtonClick"
  />
</template>
<script>
import $ from "jquery";
import NotificationSettingTestModal from "@/components/page-partials/notification-settings/SettingTestModal.vue";
import NotificationSMSBalanceModal from "@/components/page-partials/notification-settings/SMSBalanceModal.vue";
export default {
  name: "NotificationSettingNew",
  components: { NotificationSettingTestModal, NotificationSMSBalanceModal },
  data() {
    return {
      smsBalanceButtonClick: false,
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      notificationSettingData: {
        isActive: true,
        notifierTypeId: 1,
      },
      notifierTypeEmail: true,
      isSuccess: false,
      notifierTypeData: [
        {
          key: 1,
          value: this.$t(
            "NotificationSettings.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: 2,
          value: this.$t(
            "NotificationSettings.SMS",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      selectedNotifierType: [
        {
          key: 1,
          value: this.$t(
            "NotificationSettings.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
    };
  },
  methods: {
    onChangeForNotifierType: function (selected) {
      this.notifierTypeEmail = selected.key == 1 ? true : false;
      this.notificationSettingData.notifierTypeId = selected.key;
      this.setDefaultValuesForNotifierType();
    },
    setDefaultValuesForNotifierType() {
      this.notificationSettingData.host = null;
      this.notificationSettingData.userName = null;
      this.notificationSettingData.password = null;
      this.notificationSettingData.senderEmail = null;
      this.notificationSettingData.port = 0;
      this.notificationSettingData.isSsl = false;
      this.notificationSettingData.isExchangeServer = false;
    },
    smsBalanceButtonClicked() {
      this.smsBalanceButtonClick = !this.smsBalanceButtonClick;
    },
    onClear() {
      this.notificationSettingData = {
        isActive: true,
        notifierTypeId: this.notificationSettingData.notifierTypeId,
      };
      $(".form-notification-setting-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-notification-setting-new");
      form.addClass("was-validated");
      if (!form[0].checkValidity()) {
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = { ...this.notificationSettingData };
      model.userName = this.$root.encrypt(model.userName);
      model.password = this.$root.encrypt(model.password);

      if (this.notifierTypeEmail) {
        model.senderEmail = this.$root.encrypt(model.senderEmail);
        model.domain = this.$root.encrypt(model.domain);
        model.host = this.$root.encrypt(model.host);
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-NotifierSettingNew", model)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push("/NotificationSetting/List");
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
          this.errors.push(r);
        });
    },
  },
};
</script>
