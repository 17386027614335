<template>
  <div
    class="modal fade"
    :id="`modalNotificationSMSBalance`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalNotificationSMSBalanceLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`modalNotificationSMSBalanceLabel`">
            <i class="bi bi-phone"></i>
            {{
              $t(
                "Components.NotificationSMSBalanceModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="errors.length > 0" class="alert alert-warning">
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer p-4">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.NotificationSMSBalanceModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationSMSBalanceModal",
  props: ["userName", "password", "modalOpenButtonClick"],
  data() {
    return {
      errors: [],
    };
  },
  watch: {
    modalOpenButtonClick() {
      this.getSMSBalance();
    },
  },
  methods: {
    getSMSBalance() {
      var reqUrl = String.format(
        "/Lcdp-NotifierSettingGetSmsUserInfo?userName={0}&password={1}",
        this.userName,
        this.password
      );
      this.$prodGatewayAxios
        .get(reqUrl)
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            this.errors.push(response.result);
          } else {
            this.errors.push(response.msg);
          }
        })
        .finally(() => {
          this.requestSend = false;
        })
        .catch((r) => {
          this.errors.push(r);
        });
    },
  },
};
</script>
