<template>
  <div
    class="modal fade"
    :id="`modalNotificationSettingTest`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalNotificationSettingTestLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5 class="modal-title" :id="`modalNotificationSettingTestLabel`">
            <template v-if="isEmailTest">
              <i class="bi bi-envelope"></i>
              {{
                $t(
                  "Components.NotificationSettingTestModal.EmailTest",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
            <template v-else>
              <i class="bi bi-phone"></i>
              {{
                $t(
                  "Components.NotificationSettingTestModal.SMSTest",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </template>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="errors.length > 0"
            class="alert"
            :class="{
              'alert-danger': !testResponse.isOk,
              'alert-success': testResponse.isOk,
            }"
          >
            <ul class="mb-0">
              <li v-for="error in errors" v-bind:key="error">
                {{ error }}
              </li>
            </ul>
          </div>
          <template v-if="isEmailTest">
            <div class="col">
              <label for="email" class="form-label required">{{
                $t(
                  "Components.NotificationSettingTestModal.EmailAddress",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
              <input
                type="email"
                id="email"
                class="form-control"
                v-model="emailAddress"
              />
            </div>
          </template>
          <div class="col" v-show="!isEmailTest">
            <label for="phone" class="form-label required">{{
              $t(
                "Components.NotificationSettingTestModal.PhoneNumber",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <input
              type="text"
              id="notificationSettingMobileNumber"
              class="form-control"
              v-model="phoneNumber"
            />
          </div>
        </div>
        <div class="modal-footer p-4">
          <button
            type="button"
            class="btn btn-success me-2"
            :disabled="requestSend"
            @click="send"
          >
            <i class="bi bi-save" v-if="!requestSend"></i>
            <span
              v-else
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            {{
              $t(
                "Components.NotificationSettingTestModal.Send",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.NotificationSettingTestModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotificationSettingTestModal",
  props: [
    "isEmailTest",
    "userName",
    "password",
    "displayName",
    "isExchangeServer",
    "domain",
    "senderEmail",
    "host",
    "port",
    "isSsl",
  ],
  data() {
    return {
      emailAddress: "",
      phoneNumber: "",
      requestSend: false,
      testResponse: null,
      errors: [],
    };
  },
  // mounted() {
  //   this.$root.preparePhoneNumber("notificationSettingMobileNumber", "");
  // },
  methods: {
    send() {
      if (
        (this.isEmailTest && String.isNullOrWhiteSpace(this.emailAddress)) ||
        (!this.isEmailTest && String.isNullOrWhiteSpace(this.phoneNumber))
      )
        return;

      this.requestSend = true;
      this.errors = [];
      if (this.isEmailTest) {
        var emailSenderModel = {
          isExchangeServer: this.isExchangeServer,
          userName: this.$root.encrypt(this.userName),
          domain: this.$root.encrypt(this.domain),
          senderEmail: this.$root.encrypt(this.senderEmail),
          password: this.$root.encrypt(this.password),
          to: this.$root.encrypt(this.emailAddress),
          host: this.$root.encrypt(this.host),
          port: this.port,
          isSsl: this.isSsl,
        };
        this.$prodGatewayAxios
          .post(`/Lcdp-NotifierSettingTestMailSender`, emailSenderModel)
          .then((response) => {
            var result = response.data;
            this.testResponse = result;
            this.errors.push(result.msg);
          })
          .finally(() => {
            this.requestSend = false;
          })
          .catch((r) => {
            this.errors.push(r);
          });
      } else {
        var smsSenderModel = {
          userName: this.$root.encrypt(this.userName),
          password: this.$root.encrypt(this.password),
          phone: this.$root.encrypt(this.phoneNumber),
        };
        this.$prodGatewayAxios
          .post(`/Lcdp-NotifierSettingTestSmsSender`, smsSenderModel)
          .then((response) => {
            var result = response.data;
            this.testResponse = result;
            this.errors.push(result.msg);
          })
          .finally(() => {
            this.requestSend = false;
          })
          .catch((r) => {
            this.errors.push(r);
          });
      }
    },
  },
};
</script>
